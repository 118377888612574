import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Mosquitto 2.0 MQTT Broker with a CA Certificate",
  "path": "/Frequently_Asked_Question/Mosquitto_2.0_with_CA_Certificate/",
  "dateChanged": "2022-05-19",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my own TLS certificate when connecting my INSTAR WQHD camera to my Mosquitto 2 broker. But because I only use a local domain I cannot use a service like Let's Encrypt to generate it. Is it possible to generate my own CA certificate?",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - Mosquitto 2.0 MQTT Broker with a CA Certificate' dateChanged='2022-05-19' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use my own TLS certificate when connecting my INSTAR WQHD camera to my Mosquitto 2 broker. But because I only use a local domain I cannot use a service like Let` s Encrypt to generate it. Is it possible to generate my own CA certificate?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Mosquitto_2.0_with_CA_Certificate/' locationFR='/fr/Frequently_Asked_Question/Mosquitto_2.0_with_CA_Certificate/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <p><strong parentName="p">{`Q`}</strong>{`: I want to use my own TLS certificate when connecting my INSTAR WQHD camera to my Mosquitto 2 broker. But because I only use a local domain I cannot use a service like Let's Encrypt to generate it. Is it possible to generate my own CA certificate?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Yes, but you don't have to! You camera uses an authorized certificate for it's INSTAR DDNS address. If you deactivate the `}<strong parentName="p">{`Verify Certificate`}</strong>{` option in your camera's MQTT menu you can start using this certificate to encrypt the communication between your camera and Mosquitto broker. To be able to leave the certificate verification active, you would need to have some local DNS server (or an edited `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hosts`}</code>{` file on each computer) that resolves your cameras DDNS address to it's local IP address. You can add your cameras CA Root certificate to your broker configuration as the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cafile`}</code>{`. Details on how to download this certificate can be found in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Network/CA_Certificate/"
      }}>{`CA Custom Store`}</a>{` article.`}</p>
    <p>{`If you really, really, REALLY want to use your own certificate you can `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Network/CA_Certificate/#take-matters-into-your-own-hands"
      }}>{`Take Matters into your own Hands`}</a>{` and generate it yourself. Once you walked through all those steps return here to use the generated file in your Mosquitto configuration.`}</p>
    <h2 {...{
      "id": "mosquitto-server-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mosquitto-server-configuration",
        "aria-label": "mosquitto server configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mosquitto Server Configuration`}</h2>
    <p>{`Now we need to add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ca.crt`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`server.crt`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`server.key`}</code>{` to our configuration file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/mosquitto/conf.d/custom.conf`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# -----------------------------------------------------------------`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Certificate based SSL/TLS support`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# -----------------------------------------------------------------`}</span>{`
listener `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8883`}</span>{`
tls_version tlsv1.2
cafile /etc/mosquitto/certs/ca.crt
certfile /etc/mosquitto/certs/server.crt
keyfile /etc/mosquitto/certs/server.key`}</code></pre></div>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: INSTAR Full HD Camera require `}<strong parentName="p">{`tlsv1.2`}</strong>{` while INSTAR WQHD Cameras use the newer `}<strong parentName="p">{`tlsv1.3`}</strong>{`. You can replace the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`tlsv1.2`}</code>{` configuration with a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`tlsv1.3`}</code>{` if you only work with WQHD Cameras.`}</p>
    </blockquote>
    <p>{`Then restart the service:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`service`}</span>{` mosquitto restart `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`service`}</span>{` mosquitto status`}</code></pre></div>
    <h3 {...{
      "id": "testing-the-connection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#testing-the-connection",
        "aria-label": "testing the connection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing the Connection`}</h3>
    <p>{`All you need to establish a server connection is the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ca.crt`}</code>{` file on your client machine. Then you can try to subscribe to the last-will topic of your camera and should receive it's online status:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`mosquitto_sub -h debian11 -t `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'cameras/117/status/connection'`}</span>{` -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8883`}</span>{` --insecure --cafile ca.crt --tls-version tlsv1.2 -u admin -P instar -v

cameras/117/status/connection `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"online"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
cameras/117/status/connection `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"offline"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
cameras/117/status/connection `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"online"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><strong parentName="p">{`Note`}</strong>{` the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`--insecure`}</code>{` flag in the subscription above. If everything went right while generating the certificates you should be able to remove it and still connect successfully.`}</p>
    <p>{`Or with the MQTT Explorer - again we start with the `}<strong parentName="p">{`Certificate Verification`}</strong>{` deactivated. Type in the connection details and click on `}<strong parentName="p">{`Advanced`}</strong>{` to add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cafile`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/178116f9a5cf273d59e346bdc7e5fa8f/d7ab4/Mosquitto2_Broker_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACWUlEQVQoz12Py27aQBSG/QDdVIEQChQDJjDj69iA7QQbfJmLPW5iaEKdYJJusuwzZN9VpHYVJeq79MkqnESt+unTmdGv82s0guMzY+rrUx8aroxc2ahEe01naTlBlZ/8r+Fq1lyQlO1IvwHoZmTsJKVUJl9l6xagfTI2diN9B82b7vj6r+B6qJZDtezBa6HVUY86cqMNP7TlZktu7i+wWblP2rDZgt2+2ZcmvYH1qmT1JUs6ngmHjYN+qwVEUex1pEFX0yAYDwEYvk4whOAYGapl6gipCKkm0kxTUxUwGHSF90eNUFR2qm8v5q57cn6er1brPF9dXm6y7BNjLE1TjEkYRnGM4xiHYYgxdhy30/koHB4eQlk+9eYIoclkslqt0jRljGG83wuCgBCy3W7TNA2CIIoCmlyw9LNjT0Wxty/LEAZBMJvNLMvK8zxNU845IQRjHEURIYRzniRJWJEkCefctu12u129XJV933ddN89zSmkcx5zzl+3gHxhjUYXjOKIo7ssAgMVi4XmeaZqU0iRJKKVZluE34jgm1UEpo5RhTGzb7nY7Qq1Wc123LMuiKPI855xjjD3P898IgiDLspDE8yhYhsvlwuMp9f2w15eFer2uaRqllBDieV6WZWVZbjab9XpdFMVVUXDKMMEXIbv1Eh5lEb3Izq5O/eRjb/L652UFQqgsy+fn54eHh/v7+8fHx19PT9/W69PF/Lt39Vu9vfPuWPnz7MsPaxrWDt4JjUZDFEUAwHg8Ho1Guq5rFaqqvkxF03TDkGUAAZAVCCFQFHkg9euN+h9kLbMvl6kFPgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/178116f9a5cf273d59e346bdc7e5fa8f/e4706/Mosquitto2_Broker_01.avif 230w", "/en/static/178116f9a5cf273d59e346bdc7e5fa8f/d1af7/Mosquitto2_Broker_01.avif 460w", "/en/static/178116f9a5cf273d59e346bdc7e5fa8f/7f308/Mosquitto2_Broker_01.avif 920w", "/en/static/178116f9a5cf273d59e346bdc7e5fa8f/90a55/Mosquitto2_Broker_01.avif 1014w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/178116f9a5cf273d59e346bdc7e5fa8f/a0b58/Mosquitto2_Broker_01.webp 230w", "/en/static/178116f9a5cf273d59e346bdc7e5fa8f/bc10c/Mosquitto2_Broker_01.webp 460w", "/en/static/178116f9a5cf273d59e346bdc7e5fa8f/966d8/Mosquitto2_Broker_01.webp 920w", "/en/static/178116f9a5cf273d59e346bdc7e5fa8f/dd5f8/Mosquitto2_Broker_01.webp 1014w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/178116f9a5cf273d59e346bdc7e5fa8f/81c8e/Mosquitto2_Broker_01.png 230w", "/en/static/178116f9a5cf273d59e346bdc7e5fa8f/08a84/Mosquitto2_Broker_01.png 460w", "/en/static/178116f9a5cf273d59e346bdc7e5fa8f/c0255/Mosquitto2_Broker_01.png 920w", "/en/static/178116f9a5cf273d59e346bdc7e5fa8f/d7ab4/Mosquitto2_Broker_01.png 1014w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/178116f9a5cf273d59e346bdc7e5fa8f/c0255/Mosquitto2_Broker_01.png",
              "alt": "Mosquitto 2.0 MQTT Broker with a CA Certificate",
              "title": "Mosquitto 2.0 MQTT Broker with a CA Certificate",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a7716d22f5dffc381756743b7378fa65/54bf4/Mosquitto2_Broker_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABZUlEQVQoz3XQTWvCMBjA8X6EHbTdYJpWh7c2WhqyLklnYuPLpha9+IaePHgUJupgKrsWcXgRPMg+wNh3HHM6NsTf/c/zoqialriOPybttJ2BECKE7vYIIa7rYowJIfQ/z/Ns2zYMQ4lq6k1cb0EPu7cIIc55q91ud7u9Xq/ZbAZBUCqV8vl87kgIIaVECAEAFE3VgA7cLMMYQwgr1eoqDN9Xq7f1erPZhGEohOCciyPOue/7CCFd1xVN0wAAWe+eUgohLBaLz5PJ63T6MpvN5/NCofC3/I0dx4nFYoeYMUYIMU0zCILdbvfx+bndbp9GIynlSSx+Jn/frKpqMpl82BNCVCqV4XC4WCwGgwHGWJw4ru0YBlCi0WgqlarX67VarVwuNxqN5XI5Ho8ppT/vOeXnBHZziZR7WBtCaFmWaZq+7/f7/U6nc67knEvpZ2zn8ip+iNNHjDFKKWNMnLGPpWWZkcjFF4s7i0HogR7uAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a7716d22f5dffc381756743b7378fa65/e4706/Mosquitto2_Broker_02.avif 230w", "/en/static/a7716d22f5dffc381756743b7378fa65/d1af7/Mosquitto2_Broker_02.avif 460w", "/en/static/a7716d22f5dffc381756743b7378fa65/7f308/Mosquitto2_Broker_02.avif 920w", "/en/static/a7716d22f5dffc381756743b7378fa65/651ce/Mosquitto2_Broker_02.avif 1007w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a7716d22f5dffc381756743b7378fa65/a0b58/Mosquitto2_Broker_02.webp 230w", "/en/static/a7716d22f5dffc381756743b7378fa65/bc10c/Mosquitto2_Broker_02.webp 460w", "/en/static/a7716d22f5dffc381756743b7378fa65/966d8/Mosquitto2_Broker_02.webp 920w", "/en/static/a7716d22f5dffc381756743b7378fa65/aa5f8/Mosquitto2_Broker_02.webp 1007w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a7716d22f5dffc381756743b7378fa65/81c8e/Mosquitto2_Broker_02.png 230w", "/en/static/a7716d22f5dffc381756743b7378fa65/08a84/Mosquitto2_Broker_02.png 460w", "/en/static/a7716d22f5dffc381756743b7378fa65/c0255/Mosquitto2_Broker_02.png 920w", "/en/static/a7716d22f5dffc381756743b7378fa65/54bf4/Mosquitto2_Broker_02.png 1007w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a7716d22f5dffc381756743b7378fa65/c0255/Mosquitto2_Broker_02.png",
              "alt": "Mosquitto 2.0 MQTT Broker with a CA Certificate",
              "title": "Mosquitto 2.0 MQTT Broker with a CA Certificate",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on connect - if it works try activating `}<strong parentName="p">{`Validate Certificate`}</strong>{` option.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      